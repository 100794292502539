.Home-Layout {
  display: flex;
  flex-direction: column;
  padding: 6% 4% 0% 4%;
  @media screen and (min-device-width: 1281px) {
    height: 63vh;
  }
}

.Contact-Text {
  line-height: 1;
  padding-top: 2.5%;
  text-align: right;
  padding-left: 4%;
  font-size: 11pt;
}
.Home-Title {
  text-align: -webkit-right;
  justify-content: end;
  height: 25%;
  font-weight: 900;
  font-size: 1.6em;
  line-height: 1;
  width: 50%;
  @media screen and (min-width: 320px) {
    width: 75%;
    height: auto;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 1200px) {
    width: 50%;
    height: auto;
  }
}
.Home-Description {
  text-align: justify;
  height: 12.5%;
  padding-bottom: 2%;
  padding-top: 5%;
  padding-left: 4%;
  line-height: 1;
  @media screen and (min-width: 768px) {
    height: 18.75%;
  }
  @media screen and (min-width: 1200px) {
    height: auto;
  }
}

.See-More-Link {
  float: right;
}
