.search-bar {
  border-radius: 10px;
  border: 1px solid #1e1e1e;
  text-align: end;
  width: 60%;
  padding-right: 2%;
}

.AboutLayout {
  padding: 0% 0% 0% 0%;
  text-align: end;
  background-color: #1e1e1e;
  height: auto;
  overflow-x: hidden;
  @media screen and (min-device-width: 768px) and (orientation: landscape) {
    height: 100%;
  }
  @media screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 100%;
  }
  p {
    margin-bottom: 0rem;
  }
}

.AboutText {
  padding: 4% 4% 0% 0%;
  height: 30%;
  text-align: justify;
  width: 80%;
  color: #afafaf;
  font-size: 1em;
}
.mini-text {
  font-size: 0.8em;
  font-style: italic;
  // text-align: end;
  // float: right;
}
.AboutTitle {
  color: #afafaf;
  line-height: 0.9;
  font-size: 1.4em;
  padding: 4% 4% 0% 0%;

  // padding-top: 4%;
}
.text-wk-right {
  text-align: -webkit-right;
}

.text-about {
  width: 100vw;
}
