.carousel .slide img {
  height: 100vh;
  width: auto;
  margin: 0;
  overflow: hidden;
}
.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}
.carousel-wrapper,
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;
}
