.componentLayout {
  width: 100%;
  height: auto;
}

* {
  font-family: "Nunito", sans-serif;
}

body {
  height: 100%;
}

#root {
  height: 100%;
  margin: 0;
}
